import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-80 lg:h-64 rounded`
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc:
        "/7-stag.jpg",
      category: "UMRBA 7-Stag Derby",
      title: "Prize Pool: 1.2M",
      description: "Triumphing as Solo Champion, V. Ellyse Rambulis dominated the event with unmatched prowess, brilliantly led by the strategic insights of Engr Mheng Melendrez and Rommel Magwili."
    },
    {
      imageSrc:
        "/nov21.jpg",
      category: "4-Stag Derby National Banded",
      title: "Prize Pool: 400k",
      description: "Hosted by the renowned TJ Marquez, our November 21, 2023 event saw an impressive gathering of top breeders, each showcasing their finest stags in pursuit of glory and coveted prizes."
    },

    {
      imageSrc:
        "/dio.jpg",
      category: "Ulutan - 5k Per Win",
      title: "Grand Prize Giveaway: Brandnew Honda Dio",
      description: "Hosted by the renowned TJ Marquez, our November 21, 2023 event saw an impressive gathering of top breeders, each showcasing their finest stags in pursuit of glory and coveted prizes."
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Previous Event Posts</HeadingTitle>
          <HeadingDescription>Unforgettable Thrills, Exceptional Hosts: Relive Our Arena's Past Events, Where Each Battle Was a Masterpiece of Strategy and Skill!</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Description>{post.description}</Description>

              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

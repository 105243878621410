import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg';
import { SectionHeading as HeadingTitle } from '../misc/Headings.js';

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : 'flex-row',
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw` bg-contain bg-no-repeat rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144  bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: '/tuesday.png',
      subtitle: 'Tuesday',
      title: 'Derby',
      description:
        "Join us every Tuesday at our premier arena for an exhilarating Derby. Witness the vigor of stags and cocks in a dynamic display of skill and strength. Embrace the spirit of competition and tradition in a day filled with excitement. Don't miss this unique spectacle of bravery and camaraderie. Your presence will make it even more special. See you there!",
    },
    {
      imageSrc: '/thursday.png',
      subtitle: 'Thursday',
      title: '5K Per Win',
      description:
        'Exciting news! Every Thursday, our arena hosts a special event where every winner takes home an extra 5,000 pesos! Experience the thrill of competition without any pot money or registration fee. A unique opportunity to showcase skill and win big! Join us for a day of exhilarating challenges and rewarding victories. Your participation could lead to unexpected fortune. See you at the arena for this remarkable event!',
    },
    {
      imageSrc: '/friday.png',
      subtitle: 'Friday',
      title: '2 Hit Ulutan!',
      description:
        'This Friday, our arena presents "2hits Ulutan!" – an extraordinary challenge for enthusiasts. Each entry must feature two roosters, and if both emerge victorious, you\'ll claim a grand prize of 35,000 pesos! This unique format promises intense battles and double the excitement. Showcase your best roosters and aim for the top prize in a day filled with thrilling duels. Join us for an event where strategy and skill could lead to a significant reward. Let the battles begin!',
      url: 'https://timerse.com',
    },
    {
      imageSrc: '/saturday.png',
      subtitle: 'Saturday',
      title: 'Hosted Event',
      description:
        "Every Saturday, experience the thrill of cockfighting at our arena, where each event is uniquely crafted by our guest hosts. Immerse yourself in the excitement of diverse formats and enticing prizes, all determined by the creativity of our hosts. Interested in shaping the event yourself? Contact our admin to become a host and add your personal touch to this proud tradition. Join us for a day of exhilarating fights, strategic prowess, and community spirit. Witness the best in cockfighting and maybe even host your own event. Don't miss out on this Saturday's extraordinary lineup!",
    },
    {
      imageSrc: '/sunday.png',
      subtitle: 'Sunday',
      title: '5K Per Win',
      description:
        'Attention all Sabungeros! Every Sunday, our arena is proud to present the "5k per Win" event – a grand cockfighting extravaganza where each victory earns you 5,000 pesos! Prepare for an epic day with over 100 fights, showcasing the best of skill, strategy, and the unyielding spirit of our roosters. Whether you\'re a seasoned veteran or an enthusiastic newcomer, this is your chance to shine and earn big in every match you win. Join a community of passionate sabungeros in a celebration of our rich cockfighting tradition. Be part of this thrilling event and let your champion roosters lead you to victory and rewards. See you at the arena for an unforgettable day of competition and triumph!',
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Upcoming Events</HeadingTitle>
          <HeadingDescription>Here are some of the upcoming events in Texas Cockpit Arena.</HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>See Event Details</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
